<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="2">
          <CInput  v-model="description" placeholder="Description"/>
        </CCol>
        <CCol lg="2">
          <CInput v-model="model" placeholder="Model"/>
        </CCol>
        <CCol lg="2">
           <v-select 
            label="label" 
            :options="[
              {
                label: 'Gondola',
                value: 'gondola'
              },
              {
                label: 'Light/Powertools',
                value: 'light/powertools'
              },
               {
                label: 'Convan',
                value: 'convan'
              },
              {
                label: 'Lifting',
                value: 'lifting'
              },
              {
								label: 'heavy/machineries',
								value: 'Heavy/Machineries'
											}
            ]"
            :reduce="item => item.value"
            v-model="classification"
            placeholder="Classification"
          > </v-select>
        </CCol> 
        <CCol lg="2">
          <v-select 
            label="customer_name" 
            :options="currentList.data"
            :reduce="item => item.id"
            v-model="current_location_id"
            placeholder="Current Location"
          > 
          </v-select> 
        </CCol> 
        
        <CCol lg="2">
          <v-select 
            label="customer_name" 
            :options="originList.data"
            :reduce="item => item.id"
            v-model="origin_id"
            placeholder="Origin"
          > 
          </v-select>  
        </CCol>
         <CCol lg="2">
           <v-select 
            label="label" 
            :options="[
              
                  { label: 'Operational',
										value: 'Operational'
											
               }, 
               
                  { label: 'Breakdown',
										value: 'breakdown'
											
               }, 
              
                  { label: 'For Scrap',
										value: 'for scrap'
											
               },
                { label: 'Standby',
										value: 'standby'
											
               }, 
               { label: 'For Erection',
										value: 'for erection'
											
               },
                { label: 'For Inspection',
										value: 'for inspection'
											
               }, 
               { label: 'Reserve',
										value: 'for reserve'
											
               },
                { label: 'Dismantle',
										value: 'dismantle'
											
               },
               { label: 'For Rehab',
										value: 'for rehab'
											
               },
                { label: 'Purchase',
										value: 'purchase'
											
               },
                { label: 'Pull Out',
										value: 'pull-out'
											
               },
                { label: 'Transfer',
										value: 'transfer'
											
               },
                 { label: 'Advances to Subcon',
										value: 'advances to subcon'
											
               },
                 { label: 'For Repair',
										value: 'for repair'
											
               }
											
              
            ]"
            :reduce="item => item.value"
            v-model="status"
            placeholder="Status"
          > </v-select> 
        </CCol>
       <CCol lg="2">
          <CInput  v-model="asset_no" placeholder="Asset No."/>
        </CCol>
        <CCol lg="2">
          <CInput  v-model="type" placeholder="Type"/>
        </CCol>
        <!-- <CCol lg="3">
          
        </CCol> -->
        <CCol lg="2" class="float-right mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            description:"",
            classification: "",
            origin_id: "",
            current_location_id:"",
            equipment_prep_no:"",
            model:"",
            status:"",
            body_no: "",
            vehicle_type_id: "",
            maker_model_id: "",
            year: "", 
            asset_no: "",
            type:"",
            currentList: {
            	data: []
            },
            originList: {
            	data: []
            },
			makerModelList: {
				data: []
			}
          }
        },
      mounted() {
        this.getOrigin();
        this.getCurrentLocation(); 
      },
      components: {vSelect},
      methods: {
        search() {
          const data = {
          description: this.description,
           classification: this.classification,
           current_location_id: this.current_location_id,
            origin_id: this.origin_id,
            equipment_prep_no: this.equipment_prep_no,
            model: this.model,
             status: this.status,
            asset_no: this.asset_no,
            type: this.type
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.description="";
          this.classification = "";
          this.origin_id = "";
          this.current_location_id ="",
          this.equipment_prep_no="";
          this.model="",
          this.status="",
          this.asset_no="",
          this.type = "";
   
          const data = {
            description: this.description,
            classification: this.classification,
            origin_id: this.origin_id,
             current_location_id: this.current_location_id,
             equipment_prep_no: this.equipment_prep_no,
              model: this.model,
              status: this.status,
            asset_no: this.asset_no,
            type: this.type,
           
          }
          this.$emit("depot-search-query", data)
        },

        getCurrentLocation(){

            axios.get(config.api_path+'/reference/customer-list',{
            params:{
              
            }
            })
            .then(response => {
              this.currentList = response.data;
             
            })

            },

     
       getOrigin(){

	      axios.get(config.api_path+'/reference/customer-list',{
            params:{
              show_all : true
            }
            })
            .then(response => {
              this.originList = response.data;
             
            })

           },

	    
      }
    }
</script>

<style scoped>

</style>
