var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('CRow',{staticClass:"mb-0"},[_c('CCol',{attrs:{"lg":"2"}},[_c('CInput',{attrs:{"placeholder":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('CInput',{attrs:{"placeholder":"Model"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('v-select',{attrs:{"label":"label","options":[
              {
                label: 'Gondola',
                value: 'gondola'
              },
              {
                label: 'Light/Powertools',
                value: 'light/powertools'
              },
               {
                label: 'Convan',
                value: 'convan'
              },
              {
                label: 'Lifting',
                value: 'lifting'
              },
              {
								label: 'heavy/machineries',
								value: 'Heavy/Machineries'
											}
            ],"reduce":function (item) { return item.value; },"placeholder":"Classification"},model:{value:(_vm.classification),callback:function ($$v) {_vm.classification=$$v},expression:"classification"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('v-select',{attrs:{"label":"customer_name","options":_vm.currentList.data,"reduce":function (item) { return item.id; },"placeholder":"Current Location"},model:{value:(_vm.current_location_id),callback:function ($$v) {_vm.current_location_id=$$v},expression:"current_location_id"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('v-select',{attrs:{"label":"customer_name","options":_vm.originList.data,"reduce":function (item) { return item.id; },"placeholder":"Origin"},model:{value:(_vm.origin_id),callback:function ($$v) {_vm.origin_id=$$v},expression:"origin_id"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('v-select',{attrs:{"label":"label","options":[
              
                  { label: 'Operational',
										value: 'Operational'
											
               }, 
               
                  { label: 'Breakdown',
										value: 'breakdown'
											
               }, 
              
                  { label: 'For Scrap',
										value: 'for scrap'
											
               },
                { label: 'Standby',
										value: 'standby'
											
               }, 
               { label: 'For Erection',
										value: 'for erection'
											
               },
                { label: 'For Inspection',
										value: 'for inspection'
											
               }, 
               { label: 'Reserve',
										value: 'for reserve'
											
               },
                { label: 'Dismantle',
										value: 'dismantle'
											
               },
               { label: 'For Rehab',
										value: 'for rehab'
											
               },
                { label: 'Purchase',
										value: 'purchase'
											
               },
                { label: 'Pull Out',
										value: 'pull-out'
											
               },
                { label: 'Transfer',
										value: 'transfer'
											
               },
                 { label: 'Advances to Subcon',
										value: 'advances to subcon'
											
               },
                 { label: 'For Repair',
										value: 'for repair'
											
               }
											
              
            ],"reduce":function (item) { return item.value; },"placeholder":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('CInput',{attrs:{"placeholder":"Asset No."},model:{value:(_vm.asset_no),callback:function ($$v) {_vm.asset_no=$$v},expression:"asset_no"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('CInput',{attrs:{"placeholder":"Type"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('CCol',{staticClass:"float-right mt-1",attrs:{"lg":"2"}},[_c('CButton',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"info","variant":"outline"},on:{"click":_vm.search}},[_vm._v("Search")]),_c('CButton',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"info","variant":"outline"},on:{"click":_vm.clearSearch}},[_vm._v("Clear Filter")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }